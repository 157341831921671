import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import _ from "lodash"
import LayoutEn from "../components/layout.en"
import HeaderEn from "../components/header.en"
import { GatsbyImage } from "gatsby-plugin-image";
import SEO from "../components/seo"

const MeetUsPage = () => {
  const data = useStaticQuery(graphql`{
  banner: file(relativePath: {eq: "2xbanner2.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 80, layout: FULL_WIDTH)
    }
  }
  beijing: file(relativePath: {eq: "2ximg-11.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 600, layout: CONSTRAINED)
    }
  }
  shenzhen: file(relativePath: {eq: "shenzhen-building-new.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 600, layout: CONSTRAINED)
    }
  }
  shanghai: file(relativePath: {eq: "2ximg-12.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 600, layout: CONSTRAINED)
    }
  }
  hongkong: file(relativePath: {eq: "2ximg-13.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 600, layout: CONSTRAINED)
    }
  }
  chengdu: file(relativePath: {eq: "sunnwell-chengdu-new.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 600, layout: CONSTRAINED)
    }
  }
  xian: file(relativePath: {eq: "sunnwell-xian.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 600, layout: CONSTRAINED)
    }
  }
}
`)

  return (
    <LayoutEn>
      <SEO title="Meet Us" />
      <div className={`mainContent`}>
        <HeaderEn
          activePage="meetus"
          color={{ bg: "transparent", text: "#fff" }}
        />

        <div className="banner" data-bg-color="transparent" data-color="#fff">
          <GatsbyImage image={data.banner?.childImageSharp?.gatsbyImageData} alt="" />
        </div>
        <div className="main-contact">
          <div
            className="section js-viewport-animation"
            data-bg-color="#fff"
            data-color="#000"
          >
            <div className="wp1180">
              <div className="contact">
                <h2 className="tit01 tal">
                  <svg
                    className="pic"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 462.23 49.1"
                  >
                    <g>
                      <g>
                        <path d="M28.56,34.68c0-9.08-27-3.23-27-20.5C1.51,4.54,10.46,0,19,0,28.63,0,37.3,4.27,37.64,14.93h-10c-.55-4.88-3.37-6.46-8.53-6.46-2.89,0-6.95,1.37-6.95,4.88,0,8.33,27,2.34,27,20.16,0,11-9.84,15.07-19.4,15.07C9.5,48.58.07,43.49,0,32.07H10.46c.27,5.57,4.68,8.12,9.77,8.12C23.46,40.19,28.56,38.88,28.56,34.68Z" />
                        <path d="M75.42,47.55l-4.13-5.16c-3.44,3.3-7.71,5.78-12.59,5.78-8.33,0-15.21-5.85-15.21-14.38,0-5.3,2.68-9.08,6.88-12,.83-.62,1.65-1.11,2.48-1.72-2.2-2.48-3.86-5.3-3.86-8.68C49,4.47,54,0,60.76,0c6.4,0,11.7,4.2,11.7,10.87,0,4.89-3.37,7.92-6.95,10.6l6.4,7.78q2.48-2.79,4.75-5.78l6,6.12A66.38,66.38,0,0,1,77.41,36l9.36,11.56ZM58.28,26.63c-2.13,1.45-6.05,4.13-6.05,7a6.37,6.37,0,0,0,6.12,6.12c2.75,0,5.51-2.27,7.37-4.05Zm-.82-15.69c0,2,1.58,3.37,2.68,4.75,1.52-1,3.86-2.48,3.86-4.61A3,3,0,0,0,60.69,8,3.08,3.08,0,0,0,57.46,10.94Z" />
                        <path d="M135.15,47.57H122.9l-9.7-32.83-9.77,32.83H91.18L82.1.77H93.45L99,33.05,108.11.77h10.12l9.15,32.28L132.88.77h11.36Z" />
                        <path d="M151.06,10.61a5.31,5.31,0,1,1,5.31-5.3A5.31,5.31,0,0,1,151.06,10.61Zm0-9.62a4.32,4.32,0,1,0,4.33,4.32A4.32,4.32,0,0,0,151.06,1Z" />
                        <path d="M152.71,8.32,151.14,5.7H150V8.32h-.82V2.15h2c1.32,0,2.26.45,2.26,1.74A1.69,1.69,0,0,1,152,5.62l1.65,2.7ZM150,5.07h1.08c1,0,1.57-.38,1.57-1.18s-.56-1.11-1.57-1.11H150Z" />
                        <path d="M197.45,47.42a24.55,24.55,0,0,1-8.89,1.68,23.58,23.58,0,0,1-9.3-1.78,21.65,21.65,0,0,1-12-12.49,26.51,26.51,0,0,1-1.68-9.55,26.57,26.57,0,0,1,1.68-9.56A22.05,22.05,0,0,1,172,8.18a21.78,21.78,0,0,1,7.29-4.94,24.48,24.48,0,0,1,17.46-.35,17.38,17.38,0,0,1,6.78,4.36l-.89,1a15.64,15.64,0,0,0-3-2.45,19.69,19.69,0,0,0-3.57-1.75,20.29,20.29,0,0,0-3.79-1,22.85,22.85,0,0,0-3.7-.32,21.63,21.63,0,0,0-8.82,1.75,20.42,20.42,0,0,0-6.82,4.81,21.81,21.81,0,0,0-4.39,7.17A24.65,24.65,0,0,0,167,25.28a25.08,25.08,0,0,0,1.53,8.82,21.41,21.41,0,0,0,4.36,7.17,20.32,20.32,0,0,0,6.82,4.81,21.75,21.75,0,0,0,8.88,1.75,22.51,22.51,0,0,0,8.32-1.62,16.45,16.45,0,0,0,6.78-4.88l1,1A17.46,17.46,0,0,1,197.45,47.42Z" />
                        <path d="M257.3,25.28a25.69,25.69,0,0,1-1.75,9.59,22.66,22.66,0,0,1-4.85,7.51,22,22,0,0,1-7.35,4.94,24.87,24.87,0,0,1-18.48,0,21.93,21.93,0,0,1-7.35-4.94,22.66,22.66,0,0,1-4.85-7.51,25.69,25.69,0,0,1-1.75-9.59,25.69,25.69,0,0,1,1.75-9.59,22.66,22.66,0,0,1,4.85-7.51,22,22,0,0,1,7.32-4.94,24.9,24.9,0,0,1,18.54,0,22.11,22.11,0,0,1,7.32,4.94,22.66,22.66,0,0,1,4.85,7.51A25.69,25.69,0,0,1,257.3,25.28Zm-1.4,0a24.24,24.24,0,0,0-1.6-8.82,21.56,21.56,0,0,0-4.49-7.17,20.89,20.89,0,0,0-6.91-4.81,23,23,0,0,0-17.58,0,20.89,20.89,0,0,0-6.91,4.81,21.56,21.56,0,0,0-4.49,7.17,24,24,0,0,0-1.6,8.82,24.65,24.65,0,0,0,1.56,8.82,21.57,21.57,0,0,0,4.43,7.17,20.67,20.67,0,0,0,6.88,4.81,23.61,23.61,0,0,0,17.84,0,20.67,20.67,0,0,0,6.88-4.81,21.57,21.57,0,0,0,4.43-7.17A24.65,24.65,0,0,0,255.9,25.28Z" />
                        <path d="M302.59,47.83,270.23,4.51H270V47.83h-1.4V2.73h2L303,46h.19V2.73h1.4v45.1Z" />
                        <path d="M329.28,4V47.83h-1.4V4H312.53V2.73h32.1V4Z" />
                        <path d="M381.71,47.83,376.36,34.9H350.18l-5.55,12.93H343.1l19.56-45.1h1.79l18.79,45.1ZM363.49,3.62,350.68,33.69h25.23Z" />
                        <path d="M419.71,47.42a24.55,24.55,0,0,1-8.89,1.68,23.54,23.54,0,0,1-9.3-1.78,21.65,21.65,0,0,1-12-12.49,26.52,26.52,0,0,1-1.69-9.55,26.57,26.57,0,0,1,1.69-9.56,22.05,22.05,0,0,1,4.75-7.54,21.78,21.78,0,0,1,7.29-4.94,23.68,23.68,0,0,1,9.27-1.79A24,24,0,0,1,419,2.89a17.38,17.38,0,0,1,6.78,4.36l-.89,1a15.41,15.41,0,0,0-3-2.45,19.28,19.28,0,0,0-3.56-1.75,20.29,20.29,0,0,0-3.79-1,22.85,22.85,0,0,0-3.7-.32A21.6,21.6,0,0,0,402,4.48a20.32,20.32,0,0,0-6.82,4.81,21.81,21.81,0,0,0-4.39,7.17,24.65,24.65,0,0,0-1.56,8.82,25.3,25.3,0,0,0,1.52,8.82,21.43,21.43,0,0,0,4.37,7.17,20.38,20.38,0,0,0,6.81,4.81,21.84,21.84,0,0,0,8.89,1.75,22.5,22.5,0,0,0,8.31-1.62,16.55,16.55,0,0,0,6.79-4.88l1,1A17.46,17.46,0,0,1,419.71,47.42Z" />
                        <path d="M446.88,4V47.83h-1.4V4H430.12V2.73h32.11V4Z" />
                      </g>
                    </g>
                  </svg>
                </h2>
                <div className="contact-items">
                  <div className="contact-item s2">
                    <div className="contact-pic fadeIn wow">
                      <GatsbyImage image={data.beijing?.childImageSharp?.gatsbyImageData} alt="" />
                    </div>
                    <div className="contact-txt wow fadeIn">
                      <div className="inner">
                        <div className="contact-tit js-text-animation">
                          beijing
                        </div>
                        <div className="contact-sub">
                          <a className="link-btn js-text-animation">Beijing</a>
                        </div>
                        <div className="contact-info">
                          <div className="js-text-animation">
                            Unit 1206, Future Building, No. 1 East Shilipu Rd.,
                            Chaoyang Dist., Beijing, China 100024
                          </div>
                          <div className="cn js-text-animation">
                            总部 北京朝阳区东十里堡路1号未来时1206
                          </div>
                          <div>
                            <p>
                              <a
                                className="js-text-animation"
                                href="tel:+8610 85398758"
                              >
                                +8610 85398758
                              </a>
                            </p>
                            <p>
                              <a
                                className="js-text-animation"
                                href="mailto:info@sunnwell.com"
                              >
                                info@sunnwell.com
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="contact-item s2">
                    <div className="contact-pic wow fadeIn">
                      <GatsbyImage image={data.shenzhen?.childImageSharp?.gatsbyImageData} alt="" />
                    </div>
                    <div className="contact-txt wow fadeIn">
                      <div className="inner">
                        <div className="contact-tit js-text-animation">
                          SHENZHEN
                        </div>
                        <div className="contact-info">
                          <div className="js-text-animation">
                            Unit 3110 ,Hon Kwok City Center, No. 3031 Shennan
                            mid-Rd. ,Futian Dist. Shenzhen, China 518000
                          </div>
                          <div className="cn js-text-animation">
                            深圳市福田区深南中路3031号 汉国中心 3110
                          </div>
                          <div>
                            <a
                              className="js-text-animation"
                              href="tel:+8675588911662"
                            >
                              +86 755-8891 1662
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="contact-item s2">
                    <div className="contact-pic wow fadeIn">
                      <GatsbyImage image={data.shanghai?.childImageSharp?.gatsbyImageData} alt="" />
                    </div>
                    <div className="contact-txt wow fadeIn">
                      <div className="inner">
                        <div className="contact-tit js-text-animation">
                          SHANGHAI
                        </div>
                        <div className="contact-info">
                          <div className="js-text-animation">
                            Unit 301, Building 4, No. 116 Nantangbang Rd.,
                            Huangpu Dist. Shanghai,China 200000
                          </div>
                          <div className="cn js-text-animation">
                            上海黄浦区南塘滨路116号4号楼301
                          </div>
                          <div>
                            <a
                              className="js-text-animation"
                              href="tel:+8621 53022060"
                            >
                              +8621 53022060
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="contact-item s2">
                    <div className="contact-pic wow fadeIn">
                      <GatsbyImage image={data.hongkong?.childImageSharp?.gatsbyImageData} alt="" />
                    </div>
                    <div className="contact-txt wow fadeIn">
                      <div className="inner">
                        <div className="contact-tit js-text-animation">
                          HONGKONG
                        </div>
                        <div className="contact-info">
                          <div className="js-text-animation">
                            11/F, Wing Yip Center, 305-313 Queen’s Rd. Central,
                            Hong Kong 100022
                          </div>
                          <div className="cn js-text-animation">
                            香港皇后大道中305-313号永业中心
                          </div>
                          <div>
                            <a
                              className="js-text-animation"
                              href="tel:+852 21163228"
                            >
                              +852 21163228
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="contact-item s2">
                    <div className="contact-pic wow fadeIn">
                      <GatsbyImage image={data.chengdu?.childImageSharp?.gatsbyImageData} alt="" />
                    </div>
                    <div className="contact-txt wow fadeIn">
                      <div className="inner">
                        <div className="contact-tit js-text-animation">
                          CHENGDU
                        </div>
                        <div className="contact-info">
                          <div className="js-text-animation">L11 No.1 Tianxiang East Hope, Tianfu Avenue, High-tech Zone, Chengdu City</div>
                          <div className="cn js-text-animation">
                            成都市高新区天府大道东方希望天祥1号11F
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="contact-item s3">
                    <div className="contact-pic wow fadeIn">
                      <GatsbyImage image={data.xian?.childImageSharp?.gatsbyImageData} alt="" />
                    </div>
                    <div className="contact-txt wow fadeIn">
                      <div className="inner">
                        <div className="contact-tit js-text-animation">
                          XI'AN
                        </div>
                        <div className="contact-info">
                          <div className="js-text-animation">
                            L29 Building 1, SIAN CENTER High-tech Zone,Xi'an City
                          </div>
                          <div className="cn js-text-animation">西安市高新区中铁西安中心1栋29F
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutEn>
  );
}

export default MeetUsPage
